import React from 'react';
import styled from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';
import { Link } from 'react-router-dom';
import Image from '@jetshop/ui/Image';
import { Shortcodes } from '@jetshop/flight-shortcodes';

import Slider from 'react-slick';

const Container = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  ${theme('below.lg')} {
    justify-content: center;
  }

  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1em;
    margin-bottom: 1.5rem;
  }
`;

const Wrapper = styled('div')`
  display: block;
  width: 100%;

  [data-flight-image-container] {
    padding-bottom: 41.7% !important;
  }

  [data-flight-image] > img,
  [data-flight-image-placeholder] > img {
    height: auto !important;
  }
`;

const SliderWrapper = styled('div')`
  overflow: hidden;
  width: 74.52%;

  ${theme('below.lg')} {
    width: 98%;
  }

  @supports (display: grid) {
    width: 100%;
    grid-column-start: 1;
    grid-column-end: 4;
    ${theme('below.lg')} {
      width: 100%;
      grid-column-end: 5;
    }
  }
`;

// const Image = styled('img')`
//   width: auto;
//   height: auto;
//   max-height: 100%;
//   max-width: 100%;
// `;

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 800,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true
};

const InfoText = styled('div')`
  //margin: 0 0 6px 0;
  width: 23.5%;
  line-height: 1rem;
  font-size: 0.95rem;
  background: #ededed;
  padding: 1rem;
  padding: 15px 1.5% 10px 1.5%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    color: ${theme('colors.primary')};
    font-weight: ${theme('typo.fontWeightBold')};
    text-transform: none;
    font-size: 1rem;
    margin: 1rem 0;
    margin-top: 0.2rem;
    margin-bottom: 0.5rem;
    line-height: 1.4;
  }
  p {
    margin-bottom: 10px;
    line-height: 15px;
    font-size: 0.9rem;
    width: 100%;
  }

  strong {
    text-align: left;
  }

  ${theme('below.lg')} {
    display: none;
  }

  @supports (display: grid) {
    width: 100%;
    padding: 15px 7.5% 10px 7.5%;
  }
`;

const SliderImage = ({ src, link }) => {
  const baseUrl = '/pub_docs/files/image-switcher/';
  return (
    <Wrapper key={src}>
      <Link to={link ? link : '/'}>
        <Image src={baseUrl + src} sizes={[1, 1, 1 / 3, 1, 3]} />
      </Link>
    </Wrapper>
  );
};

const SliderComponent = ({ children }) => {
  return (
    <SliderWrapper>
      <Slider {...sliderSettings}>{children}</Slider>
    </SliderWrapper>
  );
};

const InfoTextComponent = ({ children }) => {
  return <InfoText>{children}</InfoText>;
};

const StartPageContent = ({ content }) => {
  return (
    <Container>
      <Shortcodes
        content={content}
        components={{
          slidercomponent: SliderComponent,
          sliderimage: SliderImage,
          infotextcomponent: InfoTextComponent
        }}
      />
    </Container>
  );
};

export default StartPageContent;

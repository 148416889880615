import Image from '@jetshop/ui/Image';
import ProductLink from '@jetshop/ui/ProductLink';
import Badges from '@jetshop/ui/ProductList/Badges';
import { cx } from 'emotion';
import React from 'react';
import styled from 'react-emotion';
import { Price } from '../Price';

const Wrapper = styled('li')`
  font-size: 1rem;
  margin-bottom: 1.5rem;
  a {
    text-decoration: none;
    color: inherit;
    display: block;
    background: white;
  }

  [data-flight-image-container] {
    border: 1px solid ${({ theme }) => theme.colors.tablegrey};
  }

  .product-card-detail {
    text-align: center;
    background: white;
    padding: 0.75em;
    line-height: 1.35;
    //border-top: 1px solid ${({ theme }) => theme.colors.tablegrey};
    width: 100%;
    
    h3 {
      font-weight: 600;
      font-size: 1rem;
      //margintext-overflow: ellipsis;
      //white-space: nowrap;
      //overflow: hidden;
    }

    h4 {
      font-weight: normal;
      font-size: 0.9rem;
      color: ${({ theme }) => theme.colors.grey};
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin: 2px 0;
    }

    ${Price.Wrapper} {
      font-weight: 700;
      color: ${({ theme }) => theme.colors.primary};
      align-items:center;
      font-size: 0.9rem;
    }

    ${Price.Old} {
      color: ${({ theme }) => theme.colors.primary};
    }

    ${Price.New} {
      color: #c60f12;
    }
  }
`;

const ImageWrapper = styled.div`
  border: 1px solid #f3f3f3;
  img {
    width: 100%;
    display: flex;
  }
`;

export function ProductCard({
  product,
  className,
  imageAspect = '1:1',
  imageSizes = [1 / 4, 1 / 3, 1 / 2, 1 / 2],
  ...linkProps
}) {
  const hasImages = product.images && product.images.length > 0;

  return (
    <Wrapper data-testid="product" className={cx('product-card', className)}>
      <ProductLink product={product} {...linkProps}>
        {hasImages ? (
          <ImageWrapper>
            <img
              sizes={imageSizes}
              aspect={imageAspect}
              alt={product.images[0].alt || product.name}
              title={product.images[0].alt || product.name}
              src={product.images[0].url}
            />
            <Badges badges={product.badges} />
          </ImageWrapper>
        ) : (
          <Image />
        )}

        <section className="product-card-detail">
          <header>
            <h3>{product.name}</h3>
            <h4 className="sub-name">{product.subName || '\u00A0'}</h4>
          </header>
          <Price
            style={{ justifyContent: 'center' }}
            price={product.price}
            previousPrice={product.previousPrice}
          />
        </section>
      </ProductLink>
    </Wrapper>
  );
}

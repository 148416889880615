import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackListEvent } from '@jetshop/core/analytics/tracking';
import React, { useEffect } from 'react';
import styled, { cx } from 'react-emotion';
import { Price } from '../Price';
import { ProductCard } from './ProductCard';

export const Wrapper = styled('ul')`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  //margin-right: -${({ theme }) => theme.space[1]};
  //margin-left: -${({ theme }) => theme.space[1]};
  width: 100%;

  ${Price.Wrapper} {
    display: flex;
  }
  ${Price.New} {
    color: #ff0000;
    margin-right: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  ${Price.Old} {
    color: ${({ theme }) => theme.colors.grey};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .product-card {
    line-height: 19px;
    min-width: 0;
    /* 
       * For browsers that do not support grid, we need to specify the width of
       * each product
       */
    width: 50%;
    // ${({ theme }) => theme.above.md} {
    //   width: 33.333%;
    // }
    ${({ theme }) => theme.above.lg} {
      width: 25%;
    }
    a {
      margin: 0 0.5em 2em 0.5em;
    }
  }

  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1em;
    //grid-row-gap: 2em;
    .product-card {
      width: auto;
      a {
        margin: 0;
      }
    }

    ${({ theme }) => theme.below.lg} {
      grid-template-columns: repeat(2, 1fr);
    }

    ${({ theme }) => theme.below.md} {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

export function ProductGrid({
  products,
  listName,
  loading,
  className,
  ...rest
}) {
  const track = useTracker();
  useEffect(() => {
    // Dont track anything if there are no products to render
    if (!products || products.length === 0) return;

    // Otherwise track a list view event
    track(trackListEvent({ listName, products }));
  }, [listName, products, track]);

  if (!products) return null;

  return (
    <Wrapper
      data-testid="product-grid"
      className={cx('product-grid', className)}
    >
      {products.map((product, index) => {
        return (
          <ProductCard
            key={index + ':' + product.articleNumber}
            product={product}
            style={{ opacity: loading ? 0.5 : 1 }}
            {...rest}
          />
        );
      })}
    </Wrapper>
  );
}

import startPageQuery from './StartPageQuery.gql';
import ProductGrid from '@jetshop/ui/ProductList/ProductGrid';
import theme from '@jetshop/ui/utils/theme';

import React, { Fragment } from 'react';
import { useQuery } from 'react-apollo';
import styled from 'react-emotion';
import MaxWidth from '../Layout/MaxWidth';
import Campaign from './Campaign';
import CampaignHeader from './CampaignHeader';
import CategoryList from './CategoryList';
import ExtraDetails from './ExtraDetails';
import LoadingPage from '../LoadingPage';

import StartPageRender from './StartPageRender';

const StartPageWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;

  > div {
    padding: 20px 20px 20px;
    background: ${theme('colors.white')};
  }
`;

export const MainSectionWrapper = styled(MaxWidth)`
  width: 100%;
  align-self: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background: ${theme('colors.white')};
  ${({ theme }) => theme.below.sm} {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const Title = styled('h4')`
  font-size: 22px;
  margin-bottom: ${({ theme }) => theme.space[1]};
  ${({ theme }) => theme.below.sm} {
    font-size: 18px;
    margin-left: ${({ theme }) => theme.space[1]};
  }
`;

const StartPageProductGrid = styled(ProductGrid)`
  justify-content: space-between;
  ${({ theme }) => theme.below.sm} {
    padding: 0 0.5rem;
    margin: 0px;
  }
  ${({ theme }) => theme.below.lg} {
    > li {
      width: 50%;
    }
  }
`;

const StartPageTitle = ({ text }) => (
  <MainSectionWrapper>
    <Title>{text}</Title>
  </MainSectionWrapper>
);

const WrappedProductGrid = ({ products }) => (
  <MainSectionWrapper>
    <StartPageProductGrid
      products={products}
      listName={'Start Page Products'}
    />
  </MainSectionWrapper>
);

const startPageComponents = {
  'Campaign Header': CampaignHeader,
  'Category List': CategoryList,
  Title: StartPageTitle,
  Campaign: Campaign,
  'Extra Details': ExtraDetails
};

const StartPage = ({ location: { pathname, key }, startPageId }) => {
  const { data, loading } = useQuery(startPageQuery, {
    variables: {
      startPageId: startPageId
    }
  });

  return (
    <Fragment>
      <StartPageWrapper>
        <StartPageRender
          data={data}
          startPageComponents={startPageComponents}
          ProductGrid={WrappedProductGrid}
          LoadingPage={LoadingPage}
        />
      </StartPageWrapper>
    </Fragment>
  );
};

export default StartPage;

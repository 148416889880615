import React from 'react';
import styled from 'react-emotion';
import MaxWidth from '../Layout/MaxWidth';
import theme from '@jetshop/ui/utils/theme';
import Image from '@jetshop/ui/Image';
import { TrendLink } from '../ui/Button';

const Background = styled('div')`
  background-color: #000000;
  width: 100%;
  margin-bottom: 32px;

  display: flex;
  flex: 1 1 50vh;
  height: 100%;
  min-height: 360px;
`;

const Content = styled(MaxWidth)``;
const HeroImage = styled(Image)`
  height: 100%;
  background-size: cover;
  background-position: 50% 25%;

  ${theme('above.lg')} {
    margin: 0 -2rem;
  }
`;

const Overlay = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 1.5rem;
  color: white;
  align-items: center;
  ${theme('below.sm')} {
    padding: 8px;
  }

  ${theme('above.lg')} {
    padding-left: 3.5rem;
    padding-right: 3.5rem;

    background: linear-gradient(
      to right,
      #000,
      rgba(0, 0, 0, 0) 10%,
      rgba(0, 0, 0, 0) 90%,
      #000
    );
  }
`;

const CampaignText = styled('h2')`
  font-size: 5rem;
  max-width: 50rem;
  margin-top: auto;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: bold;
  line-height: 105px;
  font-size: 76px;
  text-align: center;

  ${theme('below.sm')} {
    font-size: 2.875rem;
    max-width: 100;
    text-align: center;
  }
`;

const HeaderButton = styled(TrendLink)`
  margin: 2.5rem 0;
  width: 14rem;
  font-size: 1rem;
  text-transform: uppercase;
`;

export default ({ imageSrc, text, buttonText, buttonUrl }) => {
  return (
    <Background>
      <Content>
        <HeroImage src={imageSrc} aspect={null}>
          <Overlay>
            <CampaignText>{text}</CampaignText>
            <HeaderButton to={buttonUrl || '/'}>{buttonText}</HeaderButton>
          </Overlay>
        </HeroImage>
      </Content>
    </Background>
  );
};

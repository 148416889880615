import React from 'react';
import styled from 'react-emotion';
import MaxWidth from '../Layout/MaxWidth';
import theme from '@jetshop/ui/utils/theme';

const ExtraDetailsWrapper = styled('div')`
  background-color: #f7f7f7;
  width: 100%;

  ${({ theme }) => theme.below.md} {
    margin-bottom: 2rem;
  }
`;

const MainContentWrapper = styled('div')`
  display: flex;
  justify-content: space-around;
  padding: 3rem 0;

  ${theme('below.sm')} {
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 1.5rem 0.5rem;
    height: auto;
  }
`;

const IconTextWrapper = styled('div')`
  width: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  line-height: 18px;
  font-size: 14px;

  > svg {
    height: 32px;
    margin-bottom: 16px;
  }

  ${theme('below.sm')} {
    width: 50%;
    padding: 1rem 0.5rem;
  }
`;

const ExtraDetails = ({ deliveryText, heartText, paymentText, giftText }) => {
  return (
    <ExtraDetailsWrapper>
      <MaxWidth>
        <MainContentWrapper>
          <IconTextWrapper>
            <p>{deliveryText}</p>
          </IconTextWrapper>
          <IconTextWrapper>
            <p>{heartText}</p>
          </IconTextWrapper>
          <IconTextWrapper>
            <p>{paymentText}</p>
          </IconTextWrapper>
          <IconTextWrapper>
            <p>{giftText}</p>
          </IconTextWrapper>
        </MainContentWrapper>
      </MaxWidth>
    </ExtraDetailsWrapper>
  );
};

export default ExtraDetails;

import React, { Component } from 'react';
import styled from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';
import Image from '@jetshop/ui/Image/Image';
import { Link } from 'react-router-dom';

const Content = styled('div')`
  width: 100%;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.grey};

  .puff-header {
    color: ${({ theme }) => theme.colors.font};
    font-size: 1rem;
    font-weight: 700;
    display: block;
    margin: 5px 0 0;
  }

  .start-header-bar {
    font-size: 1.2rem;
    font-weight: 700;
    margin: 15px 0;
    color: ${theme('colors.primary')};
  }

  p {
    margin: 0 0 10px; 0;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }
`;

const ImageWrapper = styled('div')`
  align-self: stretch;
`;

const Item = styled('div')`
  /* 
  * For browsers that do not support grid, we need to specify the width of
  * each product
  */
  width: 48%;
  margin-right: 1%;
  margin-left: 1%;

  margin-bottom: 1.5em;

  // ${({ theme }) => theme.above.md} {
  //   width: 48%;

  // }
  ${({ theme }) => theme.above.lg} {
    width: 23.5%;

    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }

  &:only-child {
    width: 100%;

    ${Content} {
      color: ${({ theme }) => theme.colors.font};
    }
  }

  @supports (display: grid) {
    width:100%;
    margin-right: 0;
    margin-left: 0;

    a{
      margin: 0;
    }
  }

  overflow: hidden;
  /* padding: 0.5rem; */

  background-color: ${theme('colors.white')};

  a {
    width: 100%;
    color: inherit;
    text-decoration: none;
    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: center;
    img {
      width: 100%;
    }
  }
`;

const imageSizes = [1 / 4, 1 / 3, 1 / 2, 1 / 2];

const CustomImage = ({ item }) => (
  <ImageWrapper>
    {item.image !== null && (
      <Image src={item.image.url} sizes={imageSizes} aspect={'12:7|7:12'} />
    )}
  </ImageWrapper>
);

export default class CustomItem extends Component {
  render() {
    const { item, numberOfItems } = this.props;

    return (
      <Item>
        <Link to={item.linkUrl}>
          <CustomImage item={item} />
          <Content dangerouslySetInnerHTML={{ __html: item.content }} />
        </Link>
      </Item>
    );
  }
}

import React from 'react';
import Head from '@jetshop/core/components/Head';
import styled from 'react-emotion';
import MaxWidth from '../Layout/MaxWidth';
import Items from './Items';
import StartPageContent from './StartPageContent';

const Row = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;

  // &::after {
  //   content: ' ';
  //   display: block;
  //   flex: auto;
  // }

  &.row-1 > div,
  &.row-2 > div,
  &.row-3 > div {
    margin-bottom: 2rem;
  }

  [data-single-customitem='true'] {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export default ({ data }) => {
  const pageContent = data && data.startPage;

  const rows =
    pageContent &&
    data.startPage.items.reduce((acc, item) => {
      if (!acc[item.row]) {
        acc[item.row] = [];
      }

      const currentRow = acc[item.row];
      acc[item.row] = [...currentRow, item];
      return acc;
    }, {});

  if (!data) return null;
  return (
    <MaxWidth>
      {pageContent && (
        <>
          <Head data={pageContent.head} />
          <StartPageContent content={pageContent.content} />
        </>
      )}
      <div>
        {Object.entries(rows).map(([row, items]) => (
          <Row
            data-testid="grid"
            className={'columns-' + items.length + ' row-' + row}
            key={row}
          >
            <Items items={items} />
          </Row>
        ))}
      </div>
    </MaxWidth>
  );
};

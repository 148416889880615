import React, { Component, Fragment } from 'react';
import CustomItem from './CustomItem';
//import ProductComponent from '../Layout/ProductComponent';
import { ProductCard } from '../CategoryPage/ProductCard';
import { Wrapper } from '../CategoryPage/ProductGrid';

export default class Items extends Component {
  generateItemElement(item) {
    let element = <Fragment />;
    const uniqeKey = item.row + '-' + item.column;
    switch (item.item.__typename) {
      case 'Product':
        // element = <ProductComponent product={item.item} productsPerRow={4} />;
        element = <ProductCard product={item.item} />;
        break;
      case 'CustomItem':
        element = <CustomItem item={item.item} />;
        break;
      default:
      // Do nothing
    }

    return <Fragment key={uniqeKey}>{element}</Fragment>;
  }
  render() {
    const items = this.props.items.filter(function(item) {
      return item;
    });

    return (
      <Wrapper
        data-single-customitem={
          items.length === 1 &&
          items.some(i => i.item.__typename === 'CustomItem')
        }
      >
        {items.map(item => this.generateItemElement(item))}
      </Wrapper>
    );
  }
}
